import React, { useState } from 'react'
import { Col, Radio, Row, Slider } from 'antd'
import styled from '@emotion/styled'
import LinkButton from './LinkButton'
import theme from '../styles/theme'
import Vial from '../images/vial-duotone.svg'
import Seedling from '../images/seedling-duotone.svg'
import Tree from '../images/tree-duotone.svg'

const Billing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8em 0;
  padding-top: calc(3em + 64px);

  @media (max-width: 767px) {
    padding: 1em 0;
  }
`

const Plan = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  padding: 2em 0;
  text-align: center;

  p {
    margin-bottom: 5px;
  }
`

const PlanTitle = styled.div`
  color: ${theme.colors.black};
  font-size: 22px;
  margin-bottom: 10px;
`

const PlanIcon = styled.div`
  display: flex;
  align-items: flex-end;
  height: 64px;
  justify-content: center;
  margin-bottom: 1em;
`

const PlanPrice = styled.div`
  strong {
    color: ${theme.colors.material.green.primary};
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }

  margin-bottom: 1em;
`

const PlanDetails = styled.div`
  margin: 1.25em 0;
`

const Estimator = styled.div`
  margin: 3em auto;
  max-width: 700px;
`

const EstimatorSlider = styled.div`
  min-height: 60px;
  margin: 3em auto;
`

const Estimation = styled.div`
  margin: 3em auto;
`

const Count = styled.div`
  font-size: 18px;
  margin: 1em 0;
  text-align: center;

  strong {
    color: ${theme.colors.material.green.primary};
    font-size: 22px;
    padding: 0 10px;
    border-bottom: 1px dashed #eee;
  }
`

const Explainer = styled.div`
  margin: 1em 0 3em 0;
`

const StyledSlider = styled(Slider)`
  .ant-slider-mark {
    font-size: 16px;
    top: 20px;
  }

  .ant-slider-track {
    background-color: ${theme.colors.material.green['200']};
  }

  .ant-slider-dot-active {
    border-color: ${theme.colors.material.green['400']};
  }

  &:hover .ant-slider-track {
    background-color: ${theme.colors.material.green['300']};
  }

  .ant-slider-handle {
    border-color: ${theme.colors.material.green['400']};
  }

  &:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${theme.colors.material.green['500']};
  }

  .ant-slider-handle.ant-tooltip-open {
    border-color: ${theme.colors.material.green['600']};
  }

  .ant-slider-handle:focus {
    box-shadow: 0 0 0 5px ${theme.colors.material.green['100']};
  }
`

const Pricing = () => {
  const [cycle, setCycle] = useState('monthly')
  const [count, setCount] = useState(1000)

  const starterBasePrice = cycle === 'monthly' ? 30 : 25
  const growthBasePrice = cycle === 'monthly' ? 60 : 50
  const mtuPrice = cycle === 'monthly' ? 10 : 8

  const marks = {
    0: '<',
    1000: '1K',
    5000: '5K',
    10000: '10K',
    15000: '15K',
    20000: '20K',
    25000: '25K+',
  }

  const formatNumber = number => {
    const formatter = new Intl.NumberFormat()
    return formatter.format(number)
  }

  const formatCount = () => {
    switch (count) {
      case 0:
        return `< 1,000`
      case 25000:
        return `25,000+`
      default:
        return formatNumber(count)
    }
  }

  const formatTooltip = value => {
    switch (value) {
      case 0:
        return `< 1K`
      case 25000:
        return `25K+`
      default:
        return `${value / 1000}K`
    }
  }

  const estimatePrice = () => {
    if (count <= 1000) {
      return starterBasePrice
    }

    const overage = (count - 1000) / 1000
    return growthBasePrice + overage * mtuPrice
  }

  return (
    <div>
      <Row gutter={16}>
        <Col md={6} sm={24} style={{ height: '100%' }}>
          <Billing>
            <PlanTitle>Billing</PlanTitle>
            <Radio.Group
              value={cycle}
              onChange={e => setCycle(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="monthly">Monthly</Radio.Button>
              <Radio.Button value="annually">Annually</Radio.Button>
            </Radio.Group>
            <p style={{ marginTop: 10 }}>Save up to 20% with annual billing.</p>
          </Billing>
        </Col>
        <Col md={6} sm={8}>
          <Plan>
            <PlanIcon>
              <Vial viewBox="0 0 50 50" width="50px" height="50px" />
            </PlanIcon>
            <PlanTitle>Experiment</PlanTitle>
            <PlanPrice>
              <strong>Free</strong>
            </PlanPrice>
            <PlanDetails>
              <p>Free for your first 250 responses</p>
            </PlanDetails>
            <LinkButton
              to="/invite#request-invite"
              type="primary"
              size="large"
              ghost
              style={{
                background: 'white',
                borderColor: theme.colors.material.green.primary,
                color: theme.colors.material.green.primary,
                fontWeight: 700,
                textShadow: 'none',
              }}
            >
              Request invite
            </LinkButton>
          </Plan>
        </Col>
        <Col md={6} sm={8}>
          <Plan>
            <PlanIcon>
              <Seedling viewBox="0 0 57 50" width="57px" height="50px" />
            </PlanIcon>
            <PlanTitle>Starter</PlanTitle>
            <PlanPrice>
              <strong>${starterBasePrice}</strong> /month
            </PlanPrice>
            <PlanDetails>
              {cycle === 'annually' && (
                <p>${starterBasePrice * 12} billed annually</p>
              )}
              <p>Less than 1K monthly tracked users</p>
              <p>Unlimited responses</p>
              <p>Unlimited events</p>
            </PlanDetails>
            <LinkButton
              to="/invite#request-invite"
              type="primary"
              size="large"
              ghost
              style={{
                background: theme.colors.material.green.primary,
                borderColor: theme.colors.material.green.primary,
                color: 'white',
                fontWeight: 700,
                textShadow: 'none',
              }}
            >
              Request invite
            </LinkButton>
          </Plan>
        </Col>
        <Col md={6} sm={8}>
          <Plan>
            <PlanIcon>
              <Tree viewBox="0 0 48 64" width="48px" height="64px" />
            </PlanIcon>
            <PlanTitle>Growth</PlanTitle>
            <PlanPrice>
              <strong>${growthBasePrice}</strong> /month
            </PlanPrice>
            <PlanDetails>
              {cycle === 'annually' && (
                <p>${growthBasePrice * 12} billed annually</p>
              )}
              <p>Includes 1K monthly tracked users</p>
              <p>Unlimited responses</p>
              <p>Unlimited events</p>
            </PlanDetails>
            <LinkButton
              to="/invite#request-invite"
              type="primary"
              size="large"
              ghost
              style={{
                background: theme.colors.material.green.primary,
                borderColor: theme.colors.material.green.primary,
                color: 'white',
                fontWeight: 700,
                textShadow: 'none',
              }}
            >
              Request invite
            </LinkButton>
          </Plan>
        </Col>
      </Row>
      <Estimator>
        <h3 style={{ textAlign: 'center' }}>Estimate your cost</h3>
        <Count>
          I want to track <strong>{formatCount()}</strong> users per month.
        </Count>
        <Explainer>
          Your <strong>Monthly Tracked Users</strong> (MTUs) are the visitors
          and identified users you track in Sift. We only count each user once
          per month, and you can always opt not to track visitors.
        </Explainer>
        <EstimatorSlider>
          <StyledSlider
            step={1000}
            marks={marks}
            max={25000}
            value={count}
            tipFormatter={formatTooltip}
            onChange={value => setCount(value)}
            defaultValue={count}
          />
        </EstimatorSlider>
      </Estimator>
      <Estimation>
        <Row>
          <Col md={{ span: 8, offset: 8 }} sm={{ span: 12, offset: 6 }}>
            <Plan>
              {count > 1000 ? (
                <>
                  <PlanIcon>
                    <Tree viewBox="0 0 48 64" width="48px" height="64px" />
                  </PlanIcon>
                  <PlanTitle>Growth</PlanTitle>
                </>
              ) : (
                <>
                  <PlanIcon>
                    <Seedling viewBox="0 0 57 50" width="57px" height="50px" />
                  </PlanIcon>
                  <PlanTitle>Starter</PlanTitle>
                </>
              )}
              <PlanPrice>
                <p>
                  <strong>${estimatePrice()}</strong> /month
                </p>
                <p>
                  <small>Estimated monthly billing</small>
                </p>
              </PlanPrice>
              <PlanDetails>
                {cycle === 'annually' ? (
                  <>
                    <p>${formatNumber(estimatePrice() * 12)} billed annually</p>
                    <p>${starterBasePrice}/mo base for 1K MTUs</p>
                  </>
                ) : (
                  <p>${starterBasePrice}/mo base for 1K MTUs</p>
                )}
                {count > 1000 && <p>+ ${mtuPrice} per 1,000 MTU</p>}
              </PlanDetails>
              <LinkButton
                to="/invite#request-invite"
                type="primary"
                size="large"
                ghost
                style={{
                  background: theme.colors.material.green.primary,
                  borderColor: theme.colors.material.green.primary,
                  color: 'white',
                  fontWeight: 700,
                  textShadow: 'none',
                }}
              >
                Request invite
              </LinkButton>
            </Plan>
          </Col>
        </Row>
      </Estimation>
    </div>
  )
}

export default Pricing
