import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Pricing from '../components/Pricing'
import Container from '../components/Container'
import SubscribeSection from '../components/SubscribeSection'

const Section = styled.section`
  margin-bottom: 6em;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: skewY(-5deg);
  transform-origin: 0;
  background: linear-gradient(150deg, #e0f2f1 15%, #e8f5e9 70%, #f1f8e9 90%);
  top: 0;
`

const Hero = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
`

const HeroInner = styled.div`
  display: flex;
`

const HeroContent = styled.div`
  flex: 100%;
  color: #388e3c;
  text-align: center;
`

const PricingPage = () => (
  <Layout color="green">
    <SEO title="Sift" />
    <header style={{ position: 'relative' }}>
      <Background />
      <Hero>
        <Container>
          <HeroInner>
            <HeroContent>
              <h1>
                <strong>Pricing for every stage</strong>
              </h1>
              <p style={{ fontSize: '1.3em' }}>
                Our plans scale with you as you grow your users.
              </p>
            </HeroContent>
          </HeroInner>
        </Container>
      </Hero>
    </header>
    <Section>
      <Container>
        <Pricing />
      </Container>
    </Section>
    <Section>
      <div id="request-invite">
        <SubscribeSection />
      </div>
    </Section>
  </Layout>
)

export default PricingPage
